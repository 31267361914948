export const COURSE_STATUS = {
  EDITING: {
    value: 0,
    label: '编辑中'
  },
  OPENING: {
    value: 1,
    label: '开放中'
  },
  CLOSED: {
    value: 2,
    label: '已关闭'
  },
  DELETED: {
    value: 3,
    label: '已删除'
  },
  ENDED: {
    value: 4,
    label: '已结束'
  }
}

export const getStatusByValue = value => {
  const statusList = Object.keys(COURSE_STATUS)
  for (const status of statusList) {
    if (COURSE_STATUS[status].value === value) {
      return COURSE_STATUS[status]
    }
  }
  return COURSE_STATUS.CLOSED
}
